import { DistanceUnit } from '../types';

type Config = {
  distanceUnit: DistanceUnit;
  googleApiKey: string;
  bugsnagApiKey: string;
  sprinklrClientId: string;
  sprinklrEmbedId: string;
  domainCode: string;
  channel: string;
  translations?: any;
  language?: string;
  cartUrl?: string;
  ecomEnabled: boolean;
};

const config: Config = {
  distanceUnit: DistanceUnit.Metric,
  googleApiKey: '',
  bugsnagApiKey: '',
  sprinklrClientId: '',
  sprinklrEmbedId: '',
  domainCode: '',
  channel: '',
  ecomEnabled: false,
};

export default config;
