import { Cart } from '../types/ecom';
import useContextReducer, { Action } from '../hooks/useContextReducer';

type State = {
  cart?: Cart;
};

export enum ActionTypes {
  Update = 'CART/UPDATE',
}

const initialState: State = {};

const reducer = (state: State, action: Action<ActionTypes>) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.Update:
      return { ...state, cart: payload.cart };
    default:
      return state;
  }
};

const { Provider, Context } = useContextReducer<State, Action<ActionTypes>>(initialState, reducer);

export { Provider as EcomContextProvider, Context as EcomContext };
