import { Cart } from '../types/ecom';
import { useContext, useCallback, useState } from 'react';
import { EcomContext, ActionTypes } from '../contexts/ecom';
import api from '../services/ecom';
import useNotifications from './useNotifications';

export default function useEcom(): {
  cart?: Cart;
  hasStock: boolean;
  isLoading: boolean;
  updateCart: () => Promise<void>;
  addToCart: (
    articleNumber: number,
    cccid: number | null,
    quantity?: number,
    referrer?: string
  ) => Promise<void>;
  checkStock: (articleNumber: number, cccid: number | null) => Promise<void>;
  resetStockCheck: () => void;
} {
  const [state, dispatch] = useContext(EcomContext);
  const { show } = useNotifications();
  const [hasStock, setHasStock] = useState<boolean | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { cart } = state;

  const updateCart = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: cart } = await api.getCardDetails();
      dispatch({ type: ActionTypes.Update, payload: { cart } });
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  const addToCart = useCallback(
    async (articleNumber: number, cccid: number | null, quantity = 1, referrer = '') => {
      try {
        setIsLoading(true);
        await api.addToCart(articleNumber, cccid, quantity, referrer);
        await updateCart();
        show('success', 'The product was added successfully to the cart.');
      } finally {
        setIsLoading(false);
      }
    },
    [updateCart, show]
  );

  const checkStock = useCallback(
    async (articleNumber: number, cccid: number | null) => {
      try {
        setIsLoading(true);
        const resp = await api.checkStock(articleNumber, cccid);
        setHasStock(resp.data.enabled);
      } finally {
        setIsLoading(false);
      }
    },
    [setHasStock]
  );

  const resetStockCheck = useCallback(() => {
    setHasStock(undefined);
  }, [setHasStock]);

  return {
    cart,
    hasStock,
    isLoading,
    checkStock,
    addToCart,
    updateCart,
    resetStockCheck,
  };
}
