import axios, { initAxiosInstance } from './axiosConfig';

interface Payload {
  articleNumber: number;
  colorId?: number | null;
  quantity: number;
  referrer: string;
}

export default {
  initAxiosInstance,

  addToCart(articleNumber: number, cccid: number | null, quantity: number, referrer = '') {
    const payload: Payload = { articleNumber, quantity, referrer };
    if (cccid) {
      payload.colorId = cccid;
    }
    return axios.post(`/v2/cart`, payload);
  },

  checkStock(articleNumber: number, cccid: number | null) {
    return axios.get(`/stock/sku/${articleNumber}${cccid ? `/${cccid}` : ''}`);
  },

  getCardDetails() {
    return axios.get('/order/cart_details', { params: { t: new Date().getTime() } });
  },
};
