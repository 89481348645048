import React from 'react';
import { getColorNameByBgColorHex } from '../../util/color';
import { Color } from '../../types';
import LikeButton from '../LikeButton';
import Svg from '../Svg';
import { ColorTileStyled, StyledPropTypes, Overlay, ColorName, ChangeColor } from './styled';
import { Paragraph } from '../../elements';

type PropTypes = {
  color: Color;
  onChangeColor?: () => void;
  onClick?: (color: Color) => void;
  onFavorite?: (color: Color) => void;
  showColorName?: boolean;
  text?: string;
};

function ColorTile({
  color,
  text,
  onChangeColor,
  onFavorite,
  onClick,
  showColorName = true,
  spacing = '50px',
  ...rest
}: PropTypes & StyledPropTypes) {
  const colorName = getColorNameByBgColorHex(color.rgb);
  const changeColor = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChangeColor();
  };

  return (
    <ColorTileStyled {...rest} bgColor={`#${color.rgb}`} onClick={() => onClick?.(color)}>
      <Overlay>
        {showColorName && (
          <ColorName colorName={colorName}>
            {color.defaultColorName} <Svg.ArrowRight />
          </ColorName>
        )}
        {text && (
          <Paragraph textAlign="justify" color={colorName}>
            {text}
          </Paragraph>
        )}
      </Overlay>
      {onFavorite && (
        <LikeButton
          onClick={() => onFavorite(color)}
          top={spacing}
          right={spacing}
          stroke={colorName}
        />
      )}
      {onChangeColor && (
        <ChangeColor colorName={colorName} spacing={spacing} onClick={changeColor}>
          <Svg.Bucket /> Change this color
        </ChangeColor>
      )}
    </ColorTileStyled>
  );
}

export default ColorTile;
